import { observer } from "mobx-react";
import React, { useContext, useEffect } from "react";
import { RootStoreContext } from "../stores";

import SearchResultList from "./SearchResultList";

export default observer(function SearchResults({
  engines,
  query,
  selectedEngines,
}) {
  const { searches } = useContext(RootStoreContext);

  useEffect(() => {
    if (!query) return;

    searches.startSearch(query, selectedEngines);
    return () => {
      searches.stopSearch();
    };
  }, [query, selectedEngines, searches]);

  return <SearchResultList query={query} engines={engines} />;
});
