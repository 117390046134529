import { observer } from "mobx-react";
import React from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

import styles from "./SortIndicator.module.css";

export default observer(function SortIndicator({ searches, sortKey }) {
  const { ascending, sortKey: currentSortKey } = searches;
  if (currentSortKey === sortKey) {
    if (ascending) {
      return <BsChevronUp className={styles.icon} />;
    } else {
      return <BsChevronDown className={styles.icon} />;
    }
  }
  return null;
});
