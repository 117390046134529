import { observer } from "mobx-react";
import React, { useContext } from "react";
import { Badge, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { BsExclamationTriangleFill } from "react-icons/bs";

import { RootStoreContext, SearchStatus } from "../stores";
import { getEngineIconUrl } from "../utils";

export default observer(function EngineResult({ engineId }) {
  const { searches } = useContext(RootStoreContext);
  const engine = searches.engines[engineId];
  const search = searches.searchByEngine[engineId];

  let statusIcon = null;
  let badgeBg = search.results.length > 0 ? "primary" : "secondary";
  if (search.status === SearchStatus.LOADING) {
    statusIcon = (
      <Spinner animation="border" variant="dark" size="sm" className="me-2" />
    );
  } else if (search.hasError) {
    badgeBg = "danger";
    statusIcon = (
      <OverlayTrigger
        overlay={
          <Tooltip>
            Error when retrieving results.
            <br />
            Click on the engine icon (on the left) to see results on their site.
          </Tooltip>
        }
        placement="bottom"
      >
        <span className="me-2 text-danger">
          <BsExclamationTriangleFill />
        </span>
      </OverlayTrigger>
    );
  }

  return (
    <div className="d-flex align-items-center">
      <a
        href={engine.search_url.replace("%s", searches.query)}
        title={`Open results for "${searches.query}" on ${engine.name}`}
        target="_blank"
        rel="noopener noreferrer"
        className="me-2"
      >
        <img src={getEngineIconUrl(engine)} alt={engine.name} />
      </a>
      <Badge pill bg={badgeBg} className="me-2">
        {search.results.length}
      </Badge>
      {statusIcon}
    </div>
  );
});
