import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { Form } from "react-bootstrap";

import EngineCheckbox from "./EngineCheckbox";
import SearchInput from "./SearchInput";

export default inject("stores")(
  observer(
    class SearchForm extends Component {
      state = {
        query: this.props.query,
        selectedEngines: this.props.selectedEngines,
      };

      render() {
        const {
          engines,
          stores: { searches },
        } = this.props;
        const { query, selectedEngines } = this.state;
        const queryStr = query || "";

        return (
          <form onSubmit={this.submitHandler}>
            <Form.Group className="mb-2">
              {Object.values(engines).map((engine) => (
                <EngineCheckbox
                  key={engine.id}
                  engine={engine}
                  isSelected={engine.id in selectedEngines}
                  onChange={() => this.toggleEngine(engine)}
                />
              ))}
            </Form.Group>
            <SearchInput
              value={queryStr}
              onChange={this.changeHandler}
              isSearching={searches.loading}
              onCancel={searches.stopSearch}
              onSubmit={this.submitHandler}
            />
          </form>
        );
      }

      changeHandler = (evt) => {
        this.setState({ query: evt.target.value });
      };

      toggleEngine = (engine) => {
        const { selectedEngines } = this.state;
        if (engine.id in selectedEngines) {
          delete selectedEngines[engine.id];
        } else {
          selectedEngines[engine.id] = engine;
        }
        this.setState({ selectedEngines });
      };

      submitHandler = (evt) => {
        evt && evt.preventDefault();
        const { engines } = this.props;
        const { query, selectedEngines } = this.state;
        const queryStr = query || "";
        const finalEngines = Object.keys(selectedEngines).length
          ? selectedEngines
          : engines;
        this.props.onSubmit({ query: queryStr, engines: finalEngines });
      };
    }
  )
);
