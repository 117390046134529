import flatMap from "lodash/flatMap";
import { observer } from "mobx-react";
import moment from "moment-shortformat";
import numeral from "numeral";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsDownload } from "react-icons/bs";

import { getDownloadUrl, getEngineIconUrl } from "../utils";
import styles from "./SearchResult.module.css";

export default observer(function SearchResult(props) {
  const {
    download_url,
    name,
    size,
    seeds,
    leech,
    source,
    engine,
    epoch,
    download_info,
  } = props;
  const prettySize = numeral(size).format("0.00 ib");
  const finalDownloadUrl = download_info
    ? getDownloadUrl(engine.id, download_info)
    : download_url;

  let date, shortDate, longDate, dateTooltip;
  if (epoch !== "") {
    date = moment.unix(epoch);
    shortDate = date.short(true);
    longDate = date.format("YYYY-MM-DD HH:mm Z");

    dateTooltip = (
      <Tooltip id="tooltip-date">
        <strong>Uploaded around</strong>
        <div>{longDate}</div>
      </Tooltip>
    );
  }

  // Add word-break hints on dots in the name
  const nameParts = flatMap(name.split("."), (part, i) => [
    part,
    <wbr key={i} />,
    ".",
  ]);
  nameParts.pop();

  const isMagnetLink = finalDownloadUrl.startsWith("magnet:");

  const torrentLink = (
    <a
      href={source}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.torrentLink}
    >
      <img
        className={styles.engineIcon}
        src={getEngineIconUrl(engine)}
        alt={engine.name}
        title={engine.name}
      />
      {nameParts}
    </a>
  );

  const torrentInfo = (
    <div>
      {date && [
        <OverlayTrigger overlay={dateTooltip} placement="top" key="date">
          <span className="text-nowrap">{shortDate}</span>
        </OverlayTrigger>,
        <span className="verticalSeparator" key="sep">
          |
        </span>,
      ]}
      <span>{prettySize}</span>
      {finalDownloadUrl && (
        <a
          href={finalDownloadUrl}
          className={styles.downloadLink}
          target={!isMagnetLink ? "_blank" : null}
          rel="noopener noreferrer"
        >
          <BsDownload />
        </a>
      )}
    </div>
  );

  return (
    <tr>
      <td>
        <div className={styles.cellInfo}>
          {torrentLink}
          {torrentInfo}
        </div>
      </td>
      <td className="text-nowrap text-end">{seeds}</td>
      <td className="text-nowrap text-end">{leech}</td>
    </tr>
  );
});
