import React, { useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { setDocumentTitle } from "../utils";
import SearchForm from "./SearchForm";
import SearchResults from "./SearchResults";

export default function SearchPage({ engines }) {
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    setDocumentTitle(match.params.query);
  }, [match.params.query]);

  let selectedEngines = {};
  if (match.params.engines) {
    selectedEngines = (match.params.engines || "")
      .split(",")
      .reduce((selectedEngines, engineKey) => {
        selectedEngines[engineKey] = engines[engineKey];
        return selectedEngines;
      }, {});
  }

  return (
    <div>
      <SearchForm
        engines={engines}
        selectedEngines={selectedEngines}
        query={match.params.query}
        onSubmit={({ query, engines }) => {
          const urlEngines = Object.keys(engines).join(",");
          history.push(`/results/${urlEngines}/${query}`);
        }}
      />
      {match.params.query && (
        <SearchResults
          query={match.params.query}
          selectedEngines={selectedEngines}
          engines={engines}
          key={match.params.query}
        />
      )}
    </div>
  );
}
