import React, { Component, lazy, Suspense } from "react";
import { Container } from "react-bootstrap";
import { Link, Route, Switch } from "react-router-dom";

import styles from "./App.module.css";
import ChangelogPage from "./ChangelogPage";
import SearchPage from "./SearchPage";

class App extends Component {
  static defaultProps = {
    engines: {},
  };

  render() {
    const { engines } = this.props;
    const searchPage = <SearchPage engines={engines} />;
    const ContactPage = lazy(() =>
      import(/* webpackChunkName: "ContactPage" */ "./ContactPage")
    );

    return (
      <>
        <Container as="main" className={styles.main}>
          <Link to="/" className="link-dark">
            <h1 className="fs-2 mb-4 mt-3">Torrent meta-search engine</h1>
          </Link>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route exact path="/">
                {searchPage}
              </Route>
              <Route path="/results/:engines/:query">{searchPage}</Route>
              <Route exact path="/results/:engines/">
                {searchPage}
              </Route>
              <Route exact path="/changelog">
                <ChangelogPage />
              </Route>
              <Route exact path="/contact">
                <ContactPage />
              </Route>
              <Route>
                <h5>Page not found</h5>
              </Route>
            </Switch>
          </Suspense>
        </Container>
        <Container as="footer" className={`py-3 mt-2 ${styles.footer}`}>
          <Link to="/changelog" className="me-4">
            Changelog
          </Link>
          <Link to="/contact">Contact</Link>
        </Container>
      </>
    );
  }
}

export default App;
