import { observer } from "mobx-react";
import React from "react";

import SortIndicator from "./SortIndicator";
import styles from "./SearchResultTableHead.module.css";

export default observer(function SearchResultTableHead({ searches }) {
  const toggleSorter = (sortKey) => searches.toggleSort.bind(searches, sortKey);
  return (
    <thead>
      <tr>
        <th>
          <div className="d-flex align-items-center">
            <span
              onClick={toggleSorter("name")}
              className={`${styles.sortBtn} flex-grow-1`}
            >
              <SortIndicator searches={searches} sortKey="name" />
              Name
            </span>
            <div>
              <span onClick={toggleSorter("epoch")} className={styles.sortBtn}>
                <SortIndicator searches={searches} sortKey="epoch" />
                Upload date
              </span>
              <span className="verticalSeparator">|</span>
              <span onClick={toggleSorter("size")} className={styles.sortBtn}>
                <SortIndicator searches={searches} sortKey="size" />
                Size
              </span>
            </div>
          </div>
        </th>
        <th className={styles.sortBtn} onClick={toggleSorter("seeds")}>
          <SortIndicator searches={searches} sortKey="seeds" />S
        </th>
        <th className={styles.sortBtn} onClick={toggleSorter("leech")}>
          <SortIndicator searches={searches} sortKey="leech" />L
        </th>
      </tr>
    </thead>
  );
});
