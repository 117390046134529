import React, { useEffect, useState } from "react";
import changelogUrl from "../CHANGELOG.md";
import { setDocumentTitle } from "../utils";

const markdownRenderer = {
  h1: ({ children }) => <h2 className="fs-4 mb-4">{children}</h2>,
  h2: ({ children }) => <h3 className="fs-5">{children}</h3>,
};

export default function ChangelogPage() {
  const [ReactMarkdownComponent, setReactMarkdownComponent] = useState();
  const [markdownContent, setMarkdownContent] = useState();
  const [loadError, setLoadError] = useState();

  useEffect(() => {
    setDocumentTitle("Changelog");
  }, []);

  useEffect(() => {
    async function fetchContent() {
      const response = await fetch(changelogUrl);
      const data = await response.text();
      setMarkdownContent(data);
    }

    async function importComponent() {
      const module = await import("react-markdown");
      setReactMarkdownComponent(() => module.default);
    }

    Promise.all([fetchContent(), importComponent()]).catch((error) => {
      setLoadError(error);
    });
  }, []);

  if (markdownContent && ReactMarkdownComponent) {
    return (
      <ReactMarkdownComponent components={markdownRenderer}>
        {markdownContent}
      </ReactMarkdownComponent>
    );
  } else if (loadError) {
    return <div>Could not load changelog: {loadError}</div>;
  } else {
    return null;
  }
}
