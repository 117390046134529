import React from "react";
import { BsCheckSquareFill, BsSquare } from "react-icons/bs";

import { getEngineIconUrl } from "../utils";
import styles from "./EngineCheckbox.module.css";

/**
 * ToggleButton with an inline checkbox and different styles
 */
export default function SearchEngineButton({ engine, isSelected, onChange }) {
  const CheckIcon = isSelected ? BsCheckSquareFill : BsSquare;
  const inputId = `input-engine-${engine.id}`;

  return (
    <div className={`mb-2 me-2 d-inline-block ${styles.button}`}>
      <input
        type="checkbox"
        className={styles.input}
        autoComplete="off"
        name="engine"
        id={inputId}
        value={engine.id}
        checked={isSelected}
        onChange={onChange}
      />
      <label
        htmlFor={inputId}
        className="btn d-flex align-items-center"
        tabIndex="-1"
      >
        <CheckIcon className="me-2" />
        <img
          src={getEngineIconUrl(engine)}
          className={`bg-white me-1 ${styles.engineIcon}`}
          alt="icon"
        />
        <span className={styles.labelText}>{engine.name}</span>
      </label>
    </div>
  );
}
