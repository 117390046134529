import { Button, Form, InputGroup } from "react-bootstrap";
import { BsSearch, BsXOctagon } from "react-icons/bs";

export default function SearchInput({
  value,
  isSearching,
  onCancel,
  onSubmit,
  ...props
}) {
  return (
    <InputGroup className="mb-4">
      <Form.Control
        type="text"
        value={value}
        placeholder="Search terms..."
        {...props}
      />
      {isSearching && (
        <Button onClick={onCancel} variant="outline-secondary">
          <BsXOctagon />
          &nbsp;Cancel
        </Button>
      )}
      <Button onClick={onSubmit} variant="outline-primary">
        <BsSearch />
        &nbsp;Search
      </Button>
    </InputGroup>
  );
}
