import sortBy from "lodash/sortBy";
import { action, makeAutoObservable, reaction } from "mobx";
import { createContext } from "react";

import conf from "./conf";

const { EventSource } = window;

export const RootStoreContext = createContext();

export class RootStore {
  constructor() {
    this.uiState = new UiState(this);
    this.searches = new Searches(this);
  }
}

export class UiState {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }
}

export class Searches {
  query = "";
  engines = [];
  searchByEngine = {};
  results = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    // Simulate throttled computed value.
    // this.results is computed:
    // - when any sub-result array changes
    // - at most every 200ms
    reaction(
      () =>
        Object.values(this.searchByEngine).map(
          (engine) => engine.results.length
        ),
      () => {
        const results = Object.values(this.searchByEngine).flatMap(
          (engine) => engine.results
        );
        this.setResults(results);
      },
      { delay: 200 }
    );
  }

  setResults(items) {
    this.results = items;
  }

  get loading() {
    return !!Object.values(this.searchByEngine).find(
      (search) => search.status === SearchStatus.LOADING
    );
  }

  sortKey = "seeds";
  ascending = false;

  get resultsSorted() {
    let results = sortBy(this.results, this.sortKey);
    if (!this.ascending) {
      results = results.reverse();
    }
    return results;
  }

  toggleSort(sortKey) {
    if (this.sortKey === sortKey) {
      this.ascending = !this.ascending;
    } else {
      this.sortKey = sortKey;
      this.ascending = false;
    }
  }

  startSearch(query, engines) {
    this.stopSearch();
    this.query = query;
    this.engines = engines;
    this.searchByEngine = {};

    // Clear results instantaneously (bypass reaction delay declared in constructor)
    this.setResults([]);

    Object.keys(engines).forEach((engineId) => {
      this.searchByEngine[engineId] = startSearch(engineId, query);
    });
  }

  stopSearch() {
    Object.values(this.searchByEngine).forEach((search) => {
      search.abort();
    });
  }
}

export const SearchStatus = {
  LOADING: "LOADING",
  ABORTED: "ABORTED",
  DONE: "DONE",
};

function startSearch(engineId, query) {
  const url = `${conf.ENDPOINT}/search/${engineId}/${query}`;
  const es = new EventSource(url);

  const search = makeAutoObservable(
    {
      status: SearchStatus.LOADING,
      hasError: false,
      results: [],
      abort() {
        es.close();
        if (this.status === SearchStatus.LOADING) {
          this.status = SearchStatus.ABORTED;
        }
      },
    },
    {},
    { autobind: true }
  );

  const onError = action(() => {
    es.close();
    search.status = SearchStatus.ABORTED;
    search.hasError = true;
  });

  const pushResult = action((searchResult) => {
    search.results.push(searchResult);
  });

  es.addEventListener("message", (evt) => {
    try {
      const item = JSON.parse(evt.data);
      pushResult(item);
    } catch (e) {
      console.error(e);
      onError();
    }
  });
  // Server sends "success" message before closing the connection
  es.addEventListener(
    "success",
    action(() => {
      es.close();
      search.status = SearchStatus.DONE;
    })
  );
  // Will occur if connection is closed before "success" event
  es.addEventListener("error", onError);

  return search;
}
