import "bootstrap/dist/css/bootstrap.css";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "mobx-react";

import App from "./components/App";
import { RootStore, RootStoreContext } from "./stores";
import conf from "./conf";

import "whatwg-fetch";
import "./index.css";

function updateLoadingText(subtext, isError = false) {
  if (isError) {
    const spinnerDom = document.getElementById("loading-spinner");
    const titleDom = document.getElementById("loading-title");
    titleDom.textContent = "Error";
    spinnerDom.style.display = "none";
  }
  const subtextDom = document.getElementById("loading-subtext");
  subtextDom.textContent = subtext;
}

function finishLoading() {
  document.getElementById("loading-screen").style.display = "none";
  document.getElementById("root").style.display = "";
}

async function fetchSearchEngines() {
  const response = await fetch(`${conf.ENDPOINT}/engines`);
  const engineList = await response.json();
  const engineEntries = engineList.map((engine) => [engine.id, engine]);
  return Object.fromEntries(engineEntries);
}

/* eslint-disable-next-line */
function timer(msec) {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, msec);
  });
}

updateLoadingText("Retrieving search engines");

Promise.all([fetchSearchEngines()])
  .then(([engines]) => {
    updateLoadingText("Initialization");
    const rootStore = new RootStore();
    const rootComponent = (
      <React.StrictMode>
        <Router>
          <Provider stores={rootStore}>
            <RootStoreContext.Provider value={rootStore}>
              <App engines={engines} />
            </RootStoreContext.Provider>
          </Provider>
        </Router>
      </React.StrictMode>
    );
    finishLoading();
    ReactDOM.render(
      rootComponent,
      document.getElementById("root"),
      finishLoading
    );
  })
  .catch((error) => {
    updateLoadingText(error, true);
    console.error(error);
  });
