import range from "lodash/range";
import { autorun, computed, makeObservable } from "mobx";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { Col, Pagination, Row, Spinner, Table } from "react-bootstrap";

import EngineResult from "./EngineResult";
import SearchResult from "./SearchResult";
import styles from "./SearchResultList.module.css";
import SearchResultTableHead from "./SearchResultTableHead";

function extractPage(list, pageNum, itemsByPage) {
  const startIndex = itemsByPage * (pageNum - 1);
  const endIndex = startIndex + itemsByPage;
  return list.slice(startIndex, endIndex);
}

export default inject("stores")(
  observer(
    class SearchResultList extends Component {
      static defaultProps = {
        itemsByPage: 15,
      };

      state = {
        activePage: 1,
      };

      constructor(props) {
        super(props);

        makeObservable(this, {
          pageResults: computed,
          nbPages: computed,
        });
      }

      get pageResults() {
        const {
          itemsByPage,
          stores: { searches },
        } = this.props;
        return extractPage(
          searches.resultsSorted,
          this.state.activePage,
          itemsByPage
        );
      }

      get nbPages() {
        const {
          itemsByPage,
          stores: { searches },
        } = this.props;
        return Math.ceil(searches.results.length / itemsByPage);
      }

      componentDidMount() {
        // Hack to reset activePage to 1 when the order of results changes
        this.pageResetterDisposer = autorun(() => {
          const { searches } = this.props.stores;

          /* Dependencies */
          // eslint-disable-next-line
          searches.sortKey;
          //eslint-disable-next-line
          searches.ascending;

          /* Side-effects*/
          this.setState({ activePage: 1 });
        });
      }

      componentWillUnmount() {
        this.pageResetterDisposer();
      }

      render() {
        const {
          query,
          engines,
          stores: { searches },
        } = this.props;
        const { activePage } = this.state;

        return (
          <div>
            <Row
              className={`${styles.loadingIndicatorRow} align-items-end mb-2`}
            >
              <Col className="fs-5">
                {searches.results.length} results for "{query}"
              </Col>
              <Col xs="auto">
                {searches.loading && (
                  <Spinner animation="border" role="status" />
                )}
              </Col>
            </Row>
            <Row>
              {Object.values(engines)
                .filter((engine) => engine.id in searches.engines)
                .map((engine) => (
                  <Col key={engine.id} xs={3} sm={2} className="mb-2">
                    <EngineResult engineId={engine.id} />
                  </Col>
                ))}
            </Row>
            <Table striped bordered hover>
              <SearchResultTableHead searches={searches} />
              <tbody>
                {this.pageResults.map((result) => (
                  <SearchResult
                    {...result}
                    engine={engines[result.engine_id]}
                    key={result.source}
                  />
                ))}
              </tbody>
            </Table>

            <Pagination className="flex-wrap">
              {range(1, this.nbPages + 1).map((pageNum) => (
                <Pagination.Item
                  key={pageNum}
                  active={pageNum === activePage}
                  onClick={() => this.setState({ activePage: pageNum })}
                >
                  {pageNum}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>
        );
      }
    }
  )
);
