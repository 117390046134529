const conf = {
  ENDPOINT: "/api",
  DEBUG: false,
};

if (process.env.NODE_ENV === "development") {
  Object.assign(conf, {
    ENDPOINT: process.env.REACT_APP_ENDPOINT || "http://localhost:8080/api",
    DEBUG: true,
  });
}

export default conf;
