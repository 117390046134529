import conf from "./conf";

export function setDocumentTitle(title) {
  title = title || "Torrent meta-search engine";
  const fullTitle = `${title} | btsearch`;
  window.document.title = fullTitle;
}

export function getDownloadUrl(engineId, torrentInfo) {
  return `${conf.ENDPOINT}/download/${engineId}/${torrentInfo}`;
}

export function getEngineIconUrl(engine) {
  return conf.ENDPOINT + engine.icon;
}

export function getContactUrl() {
  return `${conf.ENDPOINT}/contact/messages`;
}
